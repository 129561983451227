@import "../variables";
@import "../theme-colors";

.dashboard-widgets {
  &.dashboard-icon-widget {
    display: flex;
    flex-direction: row;
    align-items: center;

    .col-3 {
      max-width: fit-content;
    }

    .icon-wrapper {
      width: 50px;
      height: 50px;
      color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: $default-radius;
      background-color: $brand-color;
    }

    .widget-data {
      margin-left: 0.75rem;
    }
  }

  .widget-data {
    h6 {
      margin-bottom: 0;
    }

    p {
      font-size: 80%;
      margin-bottom: 0;
      color: $default-secondary-color;
    }
  }
}