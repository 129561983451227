@import "../variables";
@import "../theme-colors";

.unsubscriber-page {
  height: 100vh;

  .main-panel {
    width: 100%;
    margin-left: 0;
  }
}