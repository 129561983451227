@import "variables";
@import "theme-colors";

.root-preloader {
  z-index: 9999 !important;
  background: var(--base-color) !important;
}

// Spinner bounce loader
.spinner-bounce > span {
  width: 9px;
  height: 9px;
  border-radius: 100%;
  display: inline-block;
  background-color: darken($white, 10%);
  -webkit-animation: bounceDelay 1.4s infinite ease-in-out both;
  animation: bounceDelay 1.4s infinite ease-in-out both;

  &.bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  &.bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
}

// Full container overlay loader
.overlay-loader-wrapper {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: $default-radius;
  z-index: 1;

  .spinner-bounce > span {
    width: 11px;
    height: 11px;
    background-color: $brand-color;
  }
}